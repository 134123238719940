var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2024.0.2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN || process.env.SENTRY_DSN;
const SENTRY_TUNNEL =
  process.env.SENTRY_TUNNEL || process.env.NEXT_PUBLIC_SENTRY_TUNNEL;
const SENTRY_RATE =
  process.env.SENTRY_RATE || process.env.NEXT_PUBLIC_SENTRY_RATE;
const APP_LABEL = process.env.NEXT_PUBLIC_APP_LABEL;

const allowReplay = ["dev", "demo"];

const INTEGRATIONS = allowReplay.includes(process.env?.NEXT_PUBLIC_APP_LABEL) ? [Sentry.replayIntegration()] : [] ;

Sentry.init({
  dsn: SENTRY_DSN || "",
  tunnel: SENTRY_TUNNEL || "",
  tracesSampleRate: SENTRY_RATE || 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: APP_LABEL,
  integrations: INTEGRATIONS,
  debug: process.env.NEXT_PUBLIC_SENTRY_DEBUG === 'true'
});

